import { useCallback, useState } from 'react';
import { EResponseHeaders, ESnackbarStyle, FormValues, Log } from 'shared/types';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { getLogs } from 'services/api/logsService';
import { useDebounce, useSchoolParams } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { getSortQueryParams } from 'utils/sort-utils';

type Result = {
  logs: Log[];
  fetchLogs: () => Promise<void>;
  totalPages: number;
  totalLogs: number;
  isLogsLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setSortBy: (value: string) => void;
  setFormValues: (params: FormValues) => void;
  formValues: FormValues;
  clearValues: () => void;
};

const useLogsData = (): Result => {
  const INITIAL_VALUES = {
    startedAtStart: '',
    startedAtEnd: '',
    search: '',
    userId: '',
    schoolId: ''
  };
  const [logs, setLogs] = useState<Log[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalLogs, setTotalLogs] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('');
  const [isLogsLoading, setIsLogsLoading] = useState<boolean>(true);
  const { getSchoolParams } = useSchoolParams();
  const [formValues, setFormValues] = useState<FormValues>(INITIAL_VALUES);

  const debouncedParams = useDebounce(formValues, 1000);

  const fetchLogs = useCallback(async (): Promise<void> => {
    setIsLogsLoading(true);
    try {
      const orderBy = { orderBy: sortBy || 'startedAt,DESC' };
      const response = await getLogs(currentPage, PAGE_SIZE, orderBy, {
        ...getSortQueryParams(debouncedParams),
        ...getSchoolParams()
      });
      setLogs(response.data);
      setTotalPages(+response.headers[EResponseHeaders.TOTAL_PAGES]);
      setTotalLogs(+response.headers[EResponseHeaders.TOTAL_RECORDS]);
      if (+response.headers[EResponseHeaders.TOTAL_RECORDS] <= PAGE_SIZE) setCurrentPage(1);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsLogsLoading(false);
    }
  }, [currentPage, sortBy, getSchoolParams, debouncedParams]);

  const clearValues = (): void => setFormValues(INITIAL_VALUES);

  return {
    logs,
    fetchLogs,
    totalPages,
    totalLogs,
    isLogsLoading,
    setCurrentPage,
    currentPage,
    setSortBy,
    setFormValues,
    formValues,
    clearValues
  };
};

export default useLogsData;
