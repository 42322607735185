import { School } from '.';
import React from 'react';

export type Profile = {
  about: string | null;
  firstName: string;
  id: string;
  lastName: string;
  phone: string | null;
};

export type LogUser = {
  id: string;
  firebaseId: string;
  firstName: string;
  lastName: string;
};

export type Log = {
  Guid: string;
  duration: number;
  roomId: string;
  userId: string;
  user: LogUser;
  userRole: number;
  startedAt: string;
  finishedAt: string;
  userTimezone: string | undefined;
  school: School;
};

export enum ELogsUserRoles {
  Tutee,
  Tutor
}

export enum ESnackbarStyle {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success'
}

export type RowAction = { onClick: () => void };

export enum EResponseHeaders {
  CURRENT_PAGE = 'x-current-page',
  PAGE_LIMIT = 'x-page-limit',
  PAGE_OFFSET = 'x-page-offset',
  TOTAL_PAGES = 'x-total-pages',
  TOTAL_RECORDS = 'x-total-records'
}

export enum ESortType {
  DESC = 'descend',
  ASC = 'ascend'
}

export type FirebaseIdsRange = {
  firebaseIdFrom: string;
  firebaseIdTo: string;
};

export type FormValues = {
  startedAtStart: string;
  startedAtEnd: string;
  search: string;
  userId: string;
  schoolId: string;
};

export type QueryParams = {
  schoolId?: string;
  search?: string;
  startedAtStart?: string;
  startedAtEnd?: string;
  userId?: string;
};

export type DatePeriod = {
  startedAtStart: string;
  startedAtEnd: string;
};

export type InputEvent = React.ChangeEvent<HTMLInputElement>;
