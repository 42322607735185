import { ReactNode } from 'react';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { ModalState } from 'shared/types';

// Actions

export const showModal = createAction<ReactNode | null>('modal/showModal');
export const closeModal = createAction('modal/closeModal');
export const setIsDataLoadingParameter = createAction<boolean>('modal/setIsDataLoadingParameter');

const initialState: ModalState = {
  isModalOpened: false,
  isModalDataLoading: false,
  modalWindow: null
};

// Reducer

export const modalReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(showModal, (state, action): void => {
      state.isModalOpened = true;
      state.modalWindow = action.payload;
    })
    .addCase(setIsDataLoadingParameter, (state, action): void => {
      state.isModalDataLoading = action.payload;
    })
    .addCase(closeModal, (state): void => {
      state.isModalOpened = false;
      state.modalWindow = null;
    });
});
