import {
  UserOutlined,
  PlayCircleOutlined,
  ContactsOutlined,
  BarChartOutlined,
  BankOutlined,
  QuestionCircleOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { ElementType } from 'react';
import { EAdminRoles } from 'shared/types';
import { PDFIcon } from './PDFIcon';

type SidebarItem = {
  title: string;
  link: string;
  icon: ElementType;
  allowedRoles: EAdminRoles[];
};

export const SIDEBAR_ITEMS: SidebarItem[] = [
  {
    title: 'Sessions',
    link: '/sessions',
    icon: PlayCircleOutlined,
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    title: 'School admins',
    link: '/admins',
    icon: UserOutlined,
    allowedRoles: [EAdminRoles.SUPER_ADMIN]
  },
  {
    title: 'Schools',
    link: '/schools',
    icon: BankOutlined,
    allowedRoles: [EAdminRoles.SUPER_ADMIN]
  },
  {
    title: 'VR Users',
    link: '/vr-users',
    icon: ContactsOutlined,
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    title: 'Logs',
    link: '/logs',
    icon: BarChartOutlined,
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    title: 'PDF Upload',
    link: '/pdf-upload',
    icon: PDFIcon,
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    title: 'Help/FAQ',
    link: '/help',
    icon: QuestionCircleOutlined,
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  },
  {
    title: 'Settings',
    link: '/settings',
    icon: SettingOutlined,
    allowedRoles: [EAdminRoles.SUPER_ADMIN, EAdminRoles.SCHOOL_ADMIN]
  }
];
