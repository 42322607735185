import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import {
  EResponseHeaders,
  ESnackbarStyle,
  FormValuesUpdateSchool,
  School,
  SchoolState,
  UpdateSchoolAction
} from 'shared/types';
import { AppDispatch, RootState } from '../index';
import { addSchool, deleteSchool, getSchools, updateSchool } from 'services/api/schoolsService';
import { PAGE_SIZE, LIMIT_FOR_ALL_ITEMS } from 'shared/constants/pagination';
import { setIsDataLoadingParameter } from './modalReducer';
import { openNotification } from 'utils/notification-utils';

const initialState: SchoolState = {
  allSchools: [],
  schools: [],
  currentPage: 1,
  totalPages: 0,
  totalSchools: 0,
  isSchoolsLoading: true,
  sortBy: ''
};

// Actions

export const clearSchools = createAction('schools/clearSchools');
export const deleteSchoolInAllSchoolList = createAction<string>(
  'schools/deleteSchoolInAllSchoolList'
);
export const addSchoolInAllSchoolList = createAction<School>('schools/addSchoolInAllSchoolList');
export const updateSchoolInAllSchoolList = createAction<UpdateSchoolAction>(
  'schools/updateSchoolInAllSchoolList'
);
export const setIsSchoolsLoading = createAction<boolean>('schools/setIsSchoolsLoading');
export const setCurrentPage = createAction<number>('schools/setCurrentPage');
export const setSchools = createAction<SchoolState>('schools/setSchools');
export const setAllSchools = createAction<School[]>('schools/setAllSchools');
export const setSortBy = createAction<string>('schools/setSortBy');

// Reducer

export const schoolsReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setAllSchools, (state, { payload }): void => {
      state.allSchools = payload;
    })
    .addCase(setSchools, (state, { payload }): SchoolState => payload)
    .addCase(setCurrentPage, (state, { payload }): void => {
      state.currentPage = payload;
    })
    .addCase(deleteSchoolInAllSchoolList, (state, { payload }): void => {
      state.allSchools = state.allSchools.filter((school): boolean => school.id !== payload);
    })
    .addCase(addSchoolInAllSchoolList, (state, { payload }): void => {
      const sortedSchools = [payload, ...state.allSchools].sort((a, b): number =>
        a.Name.localeCompare(b.Name)
      );
      state.allSchools = sortedSchools;
    })
    .addCase(updateSchoolInAllSchoolList, (state, { payload }): void => {
      const index = state.allSchools.findIndex((school): boolean => school.id === payload.id);
      if (index !== -1) state.allSchools[index] = { ...state.allSchools[index], ...payload.values };
    })
    .addCase(clearSchools, (): SchoolState => initialState)
    .addCase(setIsSchoolsLoading, (state, { payload }): void => {
      state.isSchoolsLoading = payload;
    })
    .addCase(setSortBy, (state, { payload }): void => {
      state.sortBy = payload;
    });
});

// Thunks

export const fetchAllSchools = createAsyncThunk<
  Promise<void>,
  void,
  { dispatch: AppDispatch; state: RootState }
>('schools/fetchAllSchools', async (_, { dispatch }): Promise<void> => {
  try {
    const response = await getSchools(1, LIMIT_FOR_ALL_ITEMS, { orderBy: 'name,ASC' });
    dispatch(setAllSchools(response.data));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e.message);
  }
});

export const fetchSchools = createAsyncThunk<
  Promise<void>,
  void,
  { dispatch: AppDispatch; state: RootState }
>('schools/fetchSchools', async (_, { dispatch, getState }): Promise<void> => {
  dispatch(setIsSchoolsLoading(true));
  try {
    const schoolsState = getState().schools;
    const response = await getSchools(schoolsState.currentPage, PAGE_SIZE, {
      orderBy: schoolsState.sortBy || 'name,ASC'
    });
    const totalSchools = +response.headers[EResponseHeaders.TOTAL_RECORDS];
    const totalPages = +response.headers[EResponseHeaders.TOTAL_PAGES];
    dispatch(setSchools({ ...schoolsState, schools: response.data, totalSchools, totalPages }));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e.message);
  } finally {
    dispatch(setIsSchoolsLoading(false));
  }
});

export const updateSchoolItem = createAsyncThunk<
  Promise<void>,
  { schoolId: string; values: FormValuesUpdateSchool },
  { dispatch: AppDispatch }
>('schools/updateSchoolItem', async ({ schoolId, values }, { dispatch }): Promise<void> => {
  dispatch(setIsDataLoadingParameter(true));
  try {
    await updateSchool(schoolId, values);
    await dispatch(fetchSchools());
    await dispatch(updateSchoolInAllSchoolList({ id: schoolId, values }));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e.message);
  } finally {
    dispatch(setIsDataLoadingParameter(false));
  }
});

export const addNewSchool = createAsyncThunk<
  Promise<void>,
  { values: FormValuesUpdateSchool },
  { dispatch: AppDispatch }
>('schools/addNewSchool', async ({ values }, { dispatch }): Promise<void> => {
  dispatch(setIsDataLoadingParameter(true));
  try {
    const response = await addSchool(values);
    await dispatch(fetchSchools());
    await dispatch(addSchoolInAllSchoolList(response.data));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e.message);
  } finally {
    dispatch(setIsDataLoadingParameter(false));
  }
});

export const removeSchoolItem = createAsyncThunk<
  Promise<void>,
  { schoolId: string },
  { dispatch: AppDispatch; state: RootState }
>('schools/fetchSchools', async ({ schoolId }, { dispatch, getState }): Promise<void> => {
  dispatch(setIsDataLoadingParameter(true));
  try {
    await deleteSchool(schoolId);
    await dispatch(fetchSchools());
    await dispatch(deleteSchoolInAllSchoolList(schoolId));
    if (getState().schools.totalSchools <= PAGE_SIZE) dispatch(setCurrentPage(1));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e.message);
  } finally {
    dispatch(setIsDataLoadingParameter(false));
  }
});
