import { useCallback, useState } from 'react';
import { EResponseHeaders, ESnackbarStyle, Session, FormValues } from 'shared/types';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { getSessions } from 'services/api/sessionsService';
import { useDebounce, useSchoolParams } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { getSortQueryParams } from 'utils/sort-utils';

type Result = {
  sessions: Session[];
  fetchSessions: () => Promise<void>;
  totalPages: number;
  totalSessions: number;
  isSessionsLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setSortBy: (value: string) => void;
  setFormValues: (params: FormValues) => void;
  formValues: FormValues;
  clearValues: () => void;
};

const useSessionsData = (): Result => {
  const INITIAL_VALUES = {
    startedAtStart: '',
    startedAtEnd: '',
    search: '',
    userId: '',
    schoolId: ''
  };
  const [sessions, setSessions] = useState<Session[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalSessions, setTotalSessions] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('');
  const [isSessionsLoading, setIsSessionsLoading] = useState<boolean>(true);
  const { getSchoolParams } = useSchoolParams();
  const [formValues, setFormValues] = useState<FormValues>(INITIAL_VALUES);

  const debouncedParams = useDebounce(formValues, 1000);

  const fetchSessions = useCallback(async (): Promise<void> => {
    setIsSessionsLoading(true);
    try {
      const orderBy = { orderBy: sortBy || 'startedAt,DESC' };
      const response = await getSessions(currentPage, PAGE_SIZE, orderBy, {
        ...getSortQueryParams(debouncedParams),
        ...getSchoolParams()
      });
      setSessions(response.data);
      setTotalPages(+response.headers[EResponseHeaders.TOTAL_PAGES]);
      setTotalSessions(+response.headers[EResponseHeaders.TOTAL_RECORDS]);
      if (+response.headers[EResponseHeaders.TOTAL_RECORDS] <= PAGE_SIZE) setCurrentPage(1);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsSessionsLoading(false);
    }
  }, [currentPage, sortBy, debouncedParams, getSchoolParams]);

  const clearValues = (): void => setFormValues(INITIAL_VALUES);

  return {
    sessions,
    fetchSessions,
    totalPages,
    totalSessions,
    isSessionsLoading,
    setCurrentPage,
    currentPage,
    setSortBy,
    setFormValues,
    formValues,
    clearValues
  };
};

export default useSessionsData;
