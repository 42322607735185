import { School } from '.';

export type VrUser = {
  id?: string;
  ID: string;
  firebaseId: string;
  FirstName: string;
  LastName: string;
  Email: string;
  IsGuest: boolean;
  vreduNumber: string;
  OrganizationId: string;
};

export type UsersState = {
  allUsers: VrUser[];
  users: VrUser[];
  totalPages: number;
  totalSchools: number;
  currentPage: number;
  isSchoolsLoading: boolean;
  sortBy: string;
};

export type CreatedVrUser = {
  firebaseId?: string;
  FirstName: string;
  LastName: string;
  Email: string;
  vreduNumber: string;
};

export type UpdatedUser = {
  profile: {
    FirstName: string;
    LastName: string;
    Email: string;
    vreduNumber: string;
  };
  school: School;
};

export type UpdatedVrUser = {
  FirstName: string;
  LastName: string;
  Email: string;
  vreduNumber: string;
};

export type TableVrUser = {
  key: string;
  ID: string;
  firebaseId?: string;
  FirstName: string;
  LastName: string;
  Email: string;
  emailName: string;
  vreduNumber: string;
  OrganizationId: string;
};

export enum EVrUserField {
  firstName = 'firstName',
  lastName = 'lastName'
}
