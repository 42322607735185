import React from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { SIDEBAR_ITEMS } from './constants';
import * as Styled from './styles';
import { Button, Menu } from 'antd';
import { useLocation } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import Logo from 'assets/images/curio-xr-logo.png';
import { logoutUser } from 'services/store/reducers/authReducer';
import { closeSidebar } from 'services/store/reducers/sidebarReducer';
import { CloseOutlined } from '@ant-design/icons';
import { clearSchools } from 'services/store/reducers/schoolsReducer';
import { Admin } from 'shared/types';
import Icon from '@ant-design/icons';
import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg';

const Sidebar = (): JSX.Element => {
  const isSidebarOpened = useAppSelector((state): boolean => state.sidebar.isSidebarOpened);
  const user = useAppSelector((state): Admin | null => state.auth.user);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const logOut = (): void => {
    dispatch(clearSchools());
    dispatch(logoutUser());
  };

  const handleMenuItemClick = (subItemLink: string): void => {
    dispatch(closeSidebar());
    history.push(`${subItemLink}`);
  };

  const handleCloseButtonClick = (): void => {
    dispatch(closeSidebar());
  };

  const allowedSidebarItems = SIDEBAR_ITEMS.filter((item): boolean =>
    item.allowedRoles.some((permissibleRole): boolean => permissibleRole === user?.role)
  );

  return (
    <>
      <Styled.SidebarContainer isSidebarOpened={isSidebarOpened}>
        <Styled.CloseButton onClick={handleCloseButtonClick}>
          <CloseOutlined style={{ fontSize: '15px' }} />
        </Styled.CloseButton>
        <Styled.SidebarContent>
          <Styled.SidebarLogo>
            <NavLink to='/sessions'>
              <img src={Logo} alt='Logo' />
              <Styled.SidebarLogoTitle>
                {process.env.REACT_APP_SITE_TITLE || 'Dashboard'}
              </Styled.SidebarLogoTitle>
            </NavLink>
          </Styled.SidebarLogo>
          <Styled.SidebarItems>
            <Menu
              mode='inline'
              theme='light'
              defaultSelectedKeys={['/']}
              selectedKeys={[location.pathname]}
            >
              {allowedSidebarItems.map(
                ({ title, icon: Icon, link }): JSX.Element => (
                  <Menu.Item
                    key={link}
                    icon={<Icon />}
                    onClick={(): void => handleMenuItemClick(link)}
                  >
                    {title}
                  </Menu.Item>
                )
              )}
            </Menu>
          </Styled.SidebarItems>
        </Styled.SidebarContent>
        <Styled.SidebarFooter>
          <Styled.SidebarUserInfo>
            <div>Logged in as</div>
            <div>{user?.email}</div>
          </Styled.SidebarUserInfo>
          <Styled.SidebarUserActions>
            <Button type='primary' onClick={logOut}>
              <Icon component={LogoutIcon} />
              <span>Logout</span>
            </Button>
          </Styled.SidebarUserActions>
        </Styled.SidebarFooter>
      </Styled.SidebarContainer>
      <Styled.Overlay isSidebarOpened={isSidebarOpened} onClick={handleCloseButtonClick} />
    </>
  );
};

export default Sidebar;
